import "datatables.net"
import "datatables.net-bs4"
import DataTable from 'datatables.net-dt';
$(document).on("turbo:load", function () {

  DataTable.ext.type.order["intl"] = function ( locales, options ) { 
      if ( window.Intl ) {
        var collator = new window.Intl.Collator( "da", options );
        var types = $.fn.dataTable.ext.type;
        delete types.order['string-pre'];
        types.order['string-asc'] = collator.compare;
        types.order['string-desc'] = function ( a, b ) {
            return collator.compare( a, b ) * -1;
        };
      }
  };
  DataTable.ext.order.intl( 'da' );

  new DataTable($("#caseable .table-js-sortable"), {
    order: [5, "desc"],
    searching: false,
    paging: false,
    info: false
  })

})