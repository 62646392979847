import Sortable from "sortablejs";
$(document).on("turbo:load", function () {

document.querySelectorAll(".sortable-milestones").forEach((sortable) => { 
  new Sortable(sortable, {
    onEnd: function($item, container, _super, event) {
        refreshPositions()
    }
  })
})


function refreshPositions() {
  let position = 1
  const arr = new Array()
  let projectId = ""
  $(".list-group-item").each(function() {
      $(this).data("position", position)
     const array = [position, $(this).data("id")]
      arr.push(array)
      projectId = $(this).data("project-id")
      position++
  })
  $.ajax({
      url: "/projects/" + projectId + "/milestone_submit_sort",
      type: "post",
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: {
          ids_and_positions: arr
      }
  })
}
})
