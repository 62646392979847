import "datatables.net"
import "datatables.net-bs4"
import DataTable from 'datatables.net-dt';


$(document).on("turbo:load", function () {

DataTable.ext.type.order["intl"] = function ( locales, options ) { 
  if ( window.Intl ) {
    var collator = new window.Intl.Collator( "da", options );
    var types = $.fn.dataTable.ext.type;
    delete types.order['string-pre'];
    types.order['string-asc'] = collator.compare;
    types.order['string-desc'] = function ( a, b ) {
        return collator.compare( a, b ) * -1;
    };
  }
};

var searchRequest = null

function refreshTable(showAll) {
  if (searchRequest != null) {
    searchRequest.abort()
  }
  let criteria = $("#health_provider_criteria").val()
  let active = $("#health_provider_active").val()
  let types = []
  $('input[name="health_provider_types"]:checked').each((index, input) => types.push(input.value))

  searchRequest = $.ajax({
    url: "/health_providers/search",
    data: {
      criteria: criteria,
      active: active,
      types: types,
      show_all: showAll
    },
    success: function (data) {
      $("#health-providers").empty()
      $("#health-providers").append(data)
      $("#health-providers").css("display","inline")
      new DataTable($("#health_providers_result"), {
        searching: false,
        paging: false,
        info: false})

      $("#show-all-providers").on("click", function(event) {
        refreshTable(true)
      })
    }
  })
}

$(function() {
  $("#health_provider_criteria").on("keyup", function (event) {
    refreshTable(false)
  })

  $("#health_provider_active").on("change", function (event) {
    refreshTable(false)
  })

  $("#health_provider_search_types input[type=\"checkbox\"]").on("change", function (event) {
    refreshTable(false)
  })
})
})
