$(document).on("turbo:load", function () {

  let fileInput = $("#file-placeholder")
  let contactDiv = $("#contact-form-div")

  $("#add-document").on("click", function(event) {
    event.preventDefault()
    let newInput = fileInput.clone()
    newInput.find("a").on("click", documentRemoveClick)
    newInput.attr("id", "").show()
    $("input[type=file]").last().parent().after(newInput)
  })

  $("#add-contact-form").on("click", function(event) {
    event.preventDefault()
    let newDiv = contactDiv.clone(true)
    newDiv.find("a.remove-contact-form").on("click", contactFormRemoveClick)
    newDiv.find("input").val(1)
    let selected = newDiv.find(":checked")
    let nameAttr = "case_note[contact_forms][" + selected.val() + "][]"
    newDiv.find("input").attr("name", nameAttr)
    $("#contact-form-div").last().after(newDiv)
  })

  $("[id$=\"contact_form_id\"]").on("change", function() {
    let option = $(this).find(":checked")
    if (option.data("content")) {
      let textarea = $("[id$=\"case_note_content\"]")
      let newContent = textarea.val() + "\n" + option.data("content")
      textarea.val(newContent)
    }
  })

  $("#contact_form_id_mass").on("change", function() {
    let option = $(this).find(":checked")
    let nameAttr = "case_note[contact_forms][" + option.val() + "][]"
    option.parent().attr("name", nameAttr)
    option.parents("#contact-form-div").find("input").attr("name", nameAttr)
  })

  function documentRemoveClick(event) {
    $(event.currentTarget).parent().remove()
  }

  function contactFormRemoveClick(event) {
    $(event.currentTarget).parent().parent().remove()
  }

  function addEventListenerToMinusAndPlus(event) {
    $(".minus").on("click", function() {
      let input = $(this).parent().find("input");
      let count = parseInt(input.val()) - 1;
      count = count < 1 ? 1 : count;
      input.val(count);
      input.change();
    });

    $(".plus").on("click", function() {
      let input = $(this).parent().find("input");
      if(10 > parseInt(input.val())){
        input.val(parseInt(input.val()) + 1);      
        input.change();
      }
    });
  }
  addEventListenerToMinusAndPlus()
})
