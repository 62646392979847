import Sortable from "sortablejs";
$(document).on("turbo:load", function () {

  document.querySelectorAll(".sortable-projects").forEach((sortable) => {
    new Sortable(sortable, {
        onEnd: function($item, container, _super, event) {
            refreshPositions()
        }
    })
  })
})
function refreshPositions() {
    let position = 1
    const arr = new Array()
    $(".list-group-item").each(function() {
        $(this).data("position", position)
       const array = [position, $(this).data("id")]
        arr.push(array)
        position++
    })
    $.ajax({
        url: "/project_submit_sort",
        type: "post",
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: {
            ids_and_positions: arr
        }
    })
}