$(document).on("turbo:load", function () {

var searchRequest = null

function refreshTable() {
  if (searchRequest != null) {
    searchRequest.abort()
  }

  let county = $("#county").val()
  let healthProviderResponsible = $("#health_provider_responsible").val()
  let system = $("#system").val()
  let active = $("#active").val()
  var providerType = window.location.search.substr(15);

  searchRequest = $.ajax({
    url: "/health_providers/index_search",
    data: {
      county: county,
      health_provider_responsible: healthProviderResponsible,
      system: system,
      active: active,
      provider_type: providerType
    },
    success: function (data) {
      $("#health_providers").empty()
      $("#health_providers").append(data)
    }
  })
}

$(function() {
  let selector = "#county, #health_provider_responsible, #system, #active, #provider_type"
  $(selector).on("change", function() {
    refreshTable()
  })
})
})