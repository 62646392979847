import "@hotwired/turbo-rails"

import * as bootstrap from 'bootstrap'
import * as ActiveStorage from "@rails/activestorage"
import "datatables.net"
import "datatables.net-bs4"
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import { Danish } from "flatpickr/dist/l10n/da.js"
import DataTable from 'datatables.net-dt';

window.bootstrap = bootstrap;

ActiveStorage.start()

flatpickr.localize(Danish)

$.fn.dataTable = DataTable;
$.fn.dataTable.ext.order.intl = function ( locales, options ) {
  if ( window.Intl ) {
      var collator = new window.Intl.Collator( locales, options );
      var types = $.fn.dataTable.ext.type;
      delete types.order['string-pre'];
      types.order['string-asc'] = collator.compare;
      types.order['string-desc'] = function ( a, b ) {
          return collator.compare( a, b ) * -1;
      };
  }
};

import "@/health_provider_search"
import "@/cases"
import "@/case_note"
import "@/case_search"
import "@/project_member_search"
import "@/health_provider_index_search"
import "@/jquery-sortable"
import "@/project_sort"
import "@/milestone_sort"







$(document).on("turbo:load", function () {  
  $.extend( $.fn.dataTable.ext.order, {
    intl: function ( locales, options ) {
      if ( window.Intl ) {
        var collator = new window.Intl.Collator( "da", options );
        var types = $.fn.dataTable.ext.type;
        delete types.order['string-pre'];
        types.order['string-asc'] = collator.compare;
        types.order['string-desc'] = function ( a, b ) {
          return collator.compare( a, b ) * -1;
        };
      }
    }
  })
  
  $.fn.dataTable.ext.order.intl( 'da' );

    flatpickr('.datetimepicker', {
        enableTime: true,
        altInput: true,
        altFormat: "d-m-Y H:i",
        locale: {
          firstDayOfWeek: 1
        }
        
    })
      flatpickr('.datepicker', {
        enableTime: true,
        altInput: true,
        altFormat: "d-m-Y",
        locale: {
          firstDayOfWeek: 1
        }
    })
    

    let icon = '<i class="bi bi-caret-down"></i>'
  $(".card-toggle .card-body").hide()
  $(".card-toggle .card-header").prepend(icon)

  $(".card-toggle .card-header").on("click", function() {
    let upArrow = $(this).find(".bi-caret-up")
    let downArrow = $(this).find(".bi-caret-down")
    upArrow.removeClass("bi-caret-up").addClass("bi-caret-down")
    downArrow.removeClass("bi-caret-down").addClass("bi-caret-up")
    $(this).parent().find(".card-body").toggle()
  })
})
