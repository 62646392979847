import "datatables.net"
import "datatables.net-bs4"
import DataTable from 'datatables.net-dt';

$(document).on("turbo:load", function () {

DataTable.ext.type.order["intl"] = function ( locales, options ) { 
  if ( window.Intl ) {
    var collator = new window.Intl.Collator( "da", options );
    var types = $.fn.dataTable.ext.type;
    delete types.order['string-pre'];
    types.order['string-asc'] = collator.compare;
    types.order['string-desc'] = function ( a, b ) {
        return collator.compare( a, b ) * -1;
    };
  }
};
DataTable.ext.order.intl( 'da' );

var searchRequest = null

function refreshTable() {
  if (searchRequest != null) {
    searchRequest.abort()
  }

  let criteria = $("#cases_criteria").val()
  let caseSubject = $("#case_subject").val()
  let caseStatus = $("#case_status").val()
  let responsibleUser = $("#responsible_user").val()
  let healthProviderType = $("#health_provider_type").val()
  let county = $("#county").val()
  let healthProviderResponsible = $("#health_provider_responsible").val()
  let system = $("#system").val()
  let currentUser = $("#current_user").is(":checked")
  let unique = $("#unique").is(":checked")
  let showAll = $("#show_all").is(":checked")

  searchRequest = $.ajax({
    url: "/cases/search",
    data: {
      criteria: criteria,
      case_subject: caseSubject,
      case_status: caseStatus,
      responsible_user: responsibleUser,
      health_provider_type: healthProviderType,
      county: county,
      health_provider_responsible: healthProviderResponsible,
      system: system,
      current_user: currentUser,
      unique: unique,
      show_all: showAll
    },
    success: function (data) {
      $("#cases").empty()
      $("#cases").append(data)
      new DataTable($("#cases #case_search_table"), {
        searching: false,
        paging: false,
        info: false,
        order: [9, "desc"]
      })

    }
  })
}

$(function() {
  $("#cases_criteria").on("keyup", function () {
    refreshTable()
  })

  let selector = "#case_subject, #case_status, #responsible_user, #health_provider_type, #county, #health_provider_responsible, #system, #current_user, #unique, #show_all"
  $(selector).on("change", function() {
    refreshTable()
  })

  new DataTable($("#cases #case_search_table"), {
    searching: false,
    paging: false,
    info: false,
    order: [9, "desc"]

  })
})
})


  